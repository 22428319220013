import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider, useWallet, } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import {
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import React, { FC, ReactNode, useMemo } from 'react';
import {SendSPLTokenToAddress} from './sendtransaction';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import {data} from '../data/data';
import Success from './success';
import Error from './error';

require('../css/App.css');
require('@solana/wallet-adapter-react-ui/styles.css');



const App: FC = () => {
    return (
        <BrowserRouter>
        <Routes>
        <Route path="/" element={<Context>
            <Content />
        </Context>} />
        <Route path="/success" element={<Success />} />
        <Route path="/error" element={<Error/>} />
        </Routes>
        </BrowserRouter>
    );
};
export default App;




const Context: FC<{ children: ReactNode }> = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Mainnet;
    

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => 'https://shy-late-water.solana-mainnet.quiknode.pro/77f46b117706b9aadc474e4252b75d64b19fbfe0/', [network]);
 
    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolflareWalletAdapter({ network }),
            new TorusWalletAdapter(),
            new LedgerWalletAdapter(),
            new SolletWalletAdapter({ network }),
            new SolletExtensionWalletAdapter({ network }),
        ],
        [network]
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};
const Content: FC = () => {

   
    const { publicKey } = useWallet();
   


    

    
    return (

        
        <div className="App">


           
          
            <ToastContainer position="top-center"/>
            <div className="nav-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <img src={require('../img/logo.png')} alt="logo" className="mt-2"/>

                    </div>
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4">
                    <div className="mt-3">
                    <WalletMultiButton />
                    </div>
                    
                    </div>

                </div>
            </div>

            </div>

            <div className="background">

            <div className="container">

                <p className="title mt-5">Boom Boom Pal Marketplace</p>

                <div className="row mt-5">
    
            {data.products.map( (item) => (

                <div className="col-12 col-lg-3"  key={item.id}>


            

                <div className="card">
                    <img src={item.image} alt="{item.image}" className="card-image"/>
                    <div className="card-body bg-dark">
                        <p>{item.name}</p>
                        <p>Price: {item.price} Herz</p>

                       {publicKey && <SendSPLTokenToAddress price={item.price} img={item.image} name={item.name}/>} 
                    </div>
         


                </div>
            
                </div>
              
            
            
            
            
            ))}
            
</div>

            </div>

            </div>


            
           
        </div>
    );
};


