// @ts-nocheck
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Transaction, PublicKey } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID, getOrCreateAssociatedTokenAccount, createTransferInstruction } from '@solana/spl-token';
import React, { FC, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useState, useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { useForm, SubmitHandler } from 'react-hook-form';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import { useNavigate } from "react-router-dom";

require('../css/sendtransaction.css');

interface productProps {
  price:number,
  img:string,
  name:string
}

type FormValues = {
  name: string;
  phone: number;
  email: string;
  address1:string;
  address2:string;
  country:string;
};


export const SendSPLTokenToAddress: FC<productProps> = props => {
    let { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();
    const amount = props.price * 100000000;
    const [show, setShow] = useState(false);
    const [showButton, setShowButton] = useState(true);
    const [modalTitle, setModalTitle] = useState('Confirm Purchase');
    const navigate = useNavigate();
    connection._confirmTransactionInitialTimeout = 100 * 1000;

    const [value, setValue] = useState({value: 'HK', label:'Hong Kong'});
    const options = useMemo(() => countryList().getData(), []);
   
    const changeHandler = value => {
      setValue(value);
    }

    const handleClose = () => setShow(false);
    const handleShow = () => {
      setShow(true);
      setModalTitle('Confirm Purchase');
    }

    const {register, handleSubmit, formState:{errors}} = useForm<FormValues>();
    
    const onSubmit: SubmitHandler<FormValues> =  useCallback(async (data) => {
    setModalTitle('Pending... Please Do not turn off Browser');
    document.getElementById('form').innerHTML = 'Pending Transaction....';
    setShowButton(false);

   try{
    
    const id = toast.loading('Approve transaction...');
    if (!publicKey) throw new WalletNotConnectedError();
    const toPublicKey = new PublicKey('8g2MYemUPSpsRTywjkhMWNQxhwhEau2nHFXv7zQaWqp5');
    const mint = new PublicKey('2QfYSYA7M6HW6LrLUoQXiUpf8EQnqPvoWdaTzPozfUvc');
  


    const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
      connection,
      publicKey,
      mint,
      publicKey,
  )

    const toTokenAccount = await getOrCreateAssociatedTokenAccount(
        connection,
        publicKey,
        mint,
        toPublicKey,
    )

    if (fromTokenAccount.amount < amount){
        toast.update(id, { render: "You dont have enough Herz", type: "error", isLoading: false });
        return;
    }



    const transaction = new Transaction().add(
        createTransferInstruction(
            fromTokenAccount.address, // source
            toTokenAccount.address, // dest
            publicKey,
            amount,
            [],
            TOKEN_PROGRAM_ID
        )
    );

    const signature = await sendTransaction(transaction, connection);

    toast.update(id, { render: <div>Pending Transaction......</div>, isLoading: true });


    await connection.confirmTransaction(signature, 'processed');


    const trandata ={
      name: data.name,
      phone: data.phone,
      email: data.email,
      address1: data.address1,
      address2: data.address2,
      country: value.label,
      p_name: props.name,
      quantity: 1,
      total_paid: amount/100000000,
      signature:signature,
      wallet: publicKey
    }


  axiosRetry(axios, { retries: 1 });
    
    axios.post(process.env.REACT_APP_API_KEY, trandata,  {
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`
    },
    },).then(() => {
      toast.update(id, { render: <div>Transaction Success!</div>, isLoading: false, type: toast.TYPE.SUCCESS });
      setModalTitle('Transaction Successful');
      document.getElementById('form').innerHTML = '<span class="sign">Transaction successful! Signature: '+signature+'</span>';
      navigate('/success',{state:{signatureData:signature}})
    }).catch((err) => {

      navigate('/error',{state:{errorData:err}})
      });

  }catch(err){
    navigate('/error',{state:{errorData:err}})

    }
  }, [publicKey, sendTransaction, connection, amount, props, value]);


  return (
    <>
    <button className='btn btn-secondary' variant="primary" onClick={handleShow}>
      Purchase
    </button>

    <Modal show={show} onHide={handleClose} size="xl" centered className="modal-bg" backdrop="static">
    <Modal.Header >
      <Modal.Title>{modalTitle}</Modal.Title>
    </Modal.Header>
    <Modal.Body>

   <span className="text-danger px-2">Disclaimer: Transaction may fail due to solana on9 speed</span> 
   <p className="text-danger px-2">Please Do not turn off browser while loading</p> 
   <div id="form" className="px-4 ">
   <div className="row mb-5">
  
      <div className="col-lg-7 col-12 border-right">

 
        <div className='mt-2 mb-2'>Please Fill in the address form below</div>
    
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          Name:
          </div>
          <input {...register("name", { required: "Name is required."})} placeholder="Name"/>
          <p className="text-danger">{errors.name?.message}</p>
          <div>
          Email:
          </div>
          <input {...register("email", {required:"Email is required", pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address"
            } })} placeholder="Email"/>
          <p className="text-danger">{errors.email?.message}</p>

          
          <div className="row">
          <div className="col-12">

            <div>
            Phone:
            </div>
            <input type="number" {...register("phone", { required: "Phone is required.", minLength: {value:8, message: "Min Length is 8"}, maxLength:{value:15, message: "Max Length is 15"}})} placeholder="Phone"/>
            <p className="text-danger">{errors.phone?.message}</p>

            </div>
            <div className="col-12">

            <div>
          Address 1:
          </div>
          <input {...register("address1", { required: "Address1 is required."})} placeholder="Address 1"/>
          <p className="text-danger">{errors.address1?.message}</p>

            </div>

            <div className="col-12">

            <div>
          Address 2:
          </div>
          <input {...register("address2", { required: "Address2 is required."})} placeholder="Address 2"/>
          <p className="text-danger">{errors.address2?.message}</p>

            </div>
         
          </div>

          <div>
         Country:
          </div>
          <Select className="selectCountry" {...register("country")} options={options} value={value} onChange={changeHandler} />
          <p className="text-danger">{errors.country?.message}</p>


          <button type="submit" className="btn btn-primary mt-3 w-100">Proceed To Payment</button>
          </form>
      </div>

      <div className="col-lg-5 col-12">

        <p>Item Summary</p>

        <div className="d-flex">
        <img className="purchase-img" src={props.img} alt="" />
        <div className='ml-4'>
        <p className='mb-1'>Name:  {props.name}</p>
        <p className='mb-1'>Price:  {props.price} Herz</p>
        <p className='mb-1'>Quantity:  1</p>
        </div>

        

        </div>

        <hr className='mt-5' />

        <div className="row">
          <div className="col-lg-7 col-12">


          </div>

          <div className="col-lg-5 col-12">
            <p>Sub-Total: {props.price} Herz</p>
            <p>Shipping: Free</p>
            <p className='final-title'>Total: {props.price} Herz</p>


          </div>


        </div>


      </div>




   </div>

   </div>
    </Modal.Body>
    <Modal.Footer>
      { showButton ? <Button className="btn btn-secondary px-5" onClick={handleClose}>Close</Button> : null}
    </Modal.Footer>
    </Modal>
</>

  );
};
