export const data={
    products: [

        {   id: '1',
            name: 'Kayan Gift Box',
            price: 25,
            image: 'https://c.tenor.com/EjfgSlNlQtoAAAAC/%E8%A9%A6%E7%95%B6%E7%9C%9F-kayan.gif',
        },
        {   id: '2',
            name: 'Ticket',
            price: 15,
            image: 'https://www.djtimes.com/wp-content/uploads/2021/09/nukleus-festival-2022-dj-times.jpg',
        }


    ]
}