// @ts-nocheck
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Success: FC = () => {
    const location = useLocation();

    return (
        <>  

        <div className="container text-center">

          <img className="mt-5" src="https://media.giphy.com/media/2vA33ikUb0Qz6/giphy.gif" alt="" />

          <h4 className="text-white mt-3">Transaction Success!</h4>
          {location && <p className="text-white mt-3 text-break">Signature: {location.state.signatureData} </p>}


        <div className="d-flex justify-content-center">
        {location && <a href={`https://solscan.io/tx/${location.state.signatureData}`} target="_blank" rel="noreferrer"><button className="btn btn-primary mr-5 px-5 py-3">View on Solscan</button></a>}
          <a href="/"><button className="btn btn-secondary px-5 py-3">Back to Home</button></a>

       </div>


        </div>
   
      
       
       </>
    );

};

export default Success;
