// @ts-nocheck
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';



const Error: FC = () => {
    const location = useLocation();

  
    return (
        <div className="container text-center">

        <img className="mt-5" src="https://media.giphy.com/media/Rkis28kMJd1aE/giphy.gif" alt="" />

        <h4 className="text-danger mt-3">Error Occured!</h4>
        {location.state.errorData.message ? <p className="text-danger mt-3">Error: {location.state.errorData.message} </p> : null}
        
       
      <div className="d-flex justify-content-center mt-4">
        <a href="https://discord.com/invite/qwMa77Ub5z" rel="noreferrer"><button className="btn btn-primary mt-3 mr-5 px-5 py-3">Contact Us on Discord</button></a>
        <a href="/"><button className="btn btn-secondary px-5 py-3 mt-3">Back to Home</button></a>
        </div>

        </div>
    );

};

export default Error;
